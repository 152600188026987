<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ClientService from "@/services/ClientService";
import Swal from "sweetalert2";

/**
 * User list component
 */
const clientService = new ClientService();
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Prestamistas",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Prestamistas",
      items: [
        {
          text: "Inicio",
        },
        {
          text: "Prestamistas",
          active: true,
        },
      ],
      userList: [
      
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        
        {
          key: "name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "lastname",
          label: "Apellido(s)",
          sortable: true,
        },
        {
          key: "email",
          label: "Correo Electrónico",
          sortable: true,
        },
        {
          key: "celphone",
          label: "Teléfono",
          sortable: true,
        },
         {
          key: "action",
          label:  "Acción",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.userList.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created(){

    this.chargeItems();
  },

  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    chargeItems(){
        clientService.getList().then(response=>{
          this.userList = response.data.clients;
        });

    },
    routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    
    deleteElement(id){
      clientService.checkInfo(id).then(async (response)=>{

        response = response.data;
        if(response.msg==='ok'){
          await clientService.delete(id);
          this.successForm();
          this.chargeItems();
        }
        if(response.msg==='error'){

          this.errorForm(response.errorsResponse);
        }

        console.log('response', response);
        
      })

      
    },
    successForm(){
      Swal.fire("¡Operación exitosa!", "El registro fué eliminado", "success");
    },
    
    errorForm(errorResponse) {
      let htmlResponse = '';

      errorResponse.forEach(element => {
        htmlResponse = htmlResponse +' '+element+ ' </br>'
        
      });
      Swal.fire({
        title: "¡Operación cancelada!",
        icon: "warning",
        html:htmlResponse,
        focusConfirm: false,
        confirmButtonText: 'Ok',
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <a @click="routeGo('/prestamistas/create')" class="btn btn-success"
                    ><i class="mdi mdi-plus me-2"></i> Agregar prestamista</a
                  >
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center fw-normal">
                    Mostrar&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control rounded bg-light border-0 ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="table table-centered table-nowrap"
                :items="userList"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                
                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                        @click="routeGo('/prestamistas/edit/'+data.item.id)"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                      
                 @click="deleteElement(data.item.id)"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                    
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
